<template>
  <article
    class="mb-6 flex"
    :class="[hasFeaturedImage ? 'justify-between' : null, post.type]"
  >
    <router-link
      :to="getPostLink(post)"
      class="thumbnail-bg h-32 w-1/3 rounded-2xl bg-gray"
      v-if="hasFeaturedImage"
    >
      <PostFeaturedImage
        :media_id="post.featured_media"
        :key="post.featured_media"
      />
      
    </router-link>
    <div v-else class="h-32"></div>
    <div
      class="post-content flex flex-col justify-between"
      :class="[
        hasFeaturedImage
          ? 'w-2/3 pl-6'
          : 'w-full p-6 border border-lighter-gray flex-1',
      ]"
    >
      <div>
        <router-link :to="getPostLink(post)">
          <h5
            class="font-bold leading-5 md:leading-6 sm:leading-5 text-lg mt-0"
            v-html="truncate(post.title.rendered)"
          ></h5>
        </router-link>
        <div class="text-mid-gray additional-info">
          <div class="text-xs">{{ formatDate(post.date) }}</div>
          <div
            class="post-excerpt text-sm my-2"
            v-if="post.excerpt"
            v-html="truncate(post.excerpt.rendered, 100)"
          ></div>
        </div>
      </div>
      <div class="flex flex-wrap justify-between gap-2 text-sm w-full">
        <OrbitAuthor
          :params="{ style: 'AuthorName', id: post.author }"
          v-if="post.author"
        />
        <div v-if="getFirstCategory(post)">
          <OrbitTerm
            :params="{ style: 'TermName', id: getFirstCategory(post) }"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
//import LazyImage from "@/components/LazyImage";
import API from "@/api";
import Util from "@/lib/Util";
import OrbitTerm from "@/lib/OrbitTerm.vue";
import OrbitAuthor from "@/lib/OrbitAuthor.vue";
import PostFeaturedImage from "@/templates/post/FeaturedImage.vue";

export default {
  props: {
    post: Object,
  },

  components: { OrbitAuthor, OrbitTerm, /*LazyImage*/ PostFeaturedImage },
  computed: {
    hasFeaturedImage() {
      return this.post.featured_media ? true : false;
    },
  },
  methods: {
    truncate: (text, textLength = 80) => Util.truncate(text, textLength),
    getPostLink: (post) => Util.getPostLink(post),
    getFirstCategory: (post) =>
      post.categories && post.categories.length ? post.categories[0] : 0,
    getFeaturedImage: (post) =>
      post.featured_image ? post.featured_image : "",
    getAuthor() {
      return API.requestUser(this.post.author);
    },
    formatDate: (dateString) => Util.formatDate(dateString),
  },
};
</script>

<style scoped>
.thumbnail-bg {
  flex: 0 0 180px;
  height: 120px;
  @apply overflow-hidden;
}

article.video .thumbnail-bg {
  @apply relative;
}
article.video .thumbnail-bg::before,
article.video .thumbnail-bg::after {
  @apply absolute top-1/2 left-1/2 z-10;
  transform: translate(-50%, -50%);
  content: "";
}
article.video .thumbnail-bg::before {
  @apply border-2 border-white rounded-full w-10 h-10;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
  background: rgba(0, 0, 0, 0.42);
}

article.video .thumbnail-bg::after {
  @apply w-0 h-0;
  border: 8px solid transparent;
  border-left: 15px solid #fff;
  margin-left: 6px;
}

.post-content {
  flex-basis: calc(100% - 180px);
}

article.video .additional-info,
.post-list-two-grid .additional-info {
  @apply hidden;
}

article.post .post-excerpt:deep(p) {
  @apply m-0;
}

@media (max-width: 768px) {
  .thumbnail-bg {
    flex-basis: 120px;
  }

  .post-content {
    flex-basis: calc(100% - 120px);
  }
}
</style>

<template>
  <div
    :class="{
      'overflow-hidden': $store.state.processing,
      hidden: !$store.state.processing,
    }"
    class="w-full"
  >
    <div class="w-full inline-block progressBar-normal"></div>
  </div>

  <div
    v-if="!isAppVersion()"
    :class="$route.path === '/' ? 'home-wrapper' : null"
  >
    <div :class="$route.name">
      <Header />
    </div>
    <main id="page" class="mb-20 sm:m-0 lg:min-h-screen">
      <router-view />
    </main>
    <div :class="$route.name">
      <Footer />
    </div>
  </div>

  <div v-if="isAppVersion()">
    <Header class="visible h-auto lg:invisible lg:h-0" />

    <!-- 3 columns where the first one is empty, second one is the main element, third one is the right sidebar -->
    <div class="lg:flex">
      <div class="lg:w-10 border lg:p-10 invisible"></div>
      <div class="w-full lg:w-8/12 px-4 lg:px-12 overflow-hidden mt-6 md:mt-10">
        <router-view />
      </div>
      <div
        class="max-w-sm w-full lg:min-h-screen lg:w-4/12 border-l border-lighter-gray"
      >
        <RightSidebar />
      </div>
    </div>
    <!-- 3 columns where the first one is empty, second one is the main element, third one is the right sidebar -->

    <StickySidebar />
  </div>

  <LoginModal />
  <CommentInputModal />
  <UserFollowModal />
  <CommentsModal />
  <PollSlidesModal />
</template>

<script>
import { defineAsyncComponent } from "vue";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import RightSidebar from "@/components/RightSidebar.vue";
import StickySidebar from "@/components/header/StickySidebar.vue";
import API from "@/api";

const LoginModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: "Chunks1" */ "@/components/LoginModal.vue")
);

const CommentInputModal = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Chunks1" */ "@/components/comment/CommentInputModal.vue"
  )
);

const UserFollowModal = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "Chunks1" */ "@/components/user-follow-modal/UserFollowModal.vue"
  )
);

const CommentsModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: "Chunks1" */ "@/templates/comment/modal.vue")
);

const PollSlidesModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: "Chunks1" */ "@/templates/polls/Modal.vue")
);

export default {
  components: {
    Header,
    Footer,
    StickySidebar,
    RightSidebar,
    LoginModal,
    CommentInputModal,
    UserFollowModal,
    CommentsModal,
    PollSlidesModal
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    this.$store.commit("getLocalSettings");


    if (this.$store.state && this.$store.state.localSettings) {
      if (
        !this.$store.state.localSettings.version ||
        this.$store.state.localSettings.version != window.ykasettings.version
      ) {
        //console.log( 'Needs logout' );

        //console.log( this.$store.state.localSettings )

        // FLUSH LOCAL SETTINGS FROM THE BROWSER
        this.$store.commit( 'flushLocalSettings' );

        // SILENTLY LOG OUT
        API.requestLogout( this.$store.state.localSettings );

        //this.$router.push("Logout");
      }
    }

  },
  methods: {
    isAppVersion() {
      var allowedRoutes = [
        //"SingleVideo",
        //"SinglePost",
        "Search",
        "Archive",
        "SubCategory",
        "Author",
        "EditProfile",
        "Newsfeed",
        "AuthorPolls",
        "CreatePoll",
        //"SinglePoll",
        //"SingleNote",
        "Location",
      ];
      if (allowedRoutes.includes(this.$route.name)) return true;
      return false;
    },
  },
};
</script>
<style scoped>
.progressBar-normal {
  background: linear-gradient(
    90deg,
    rgba(153, 153, 153, 1) 0%,
    rgba(94, 203, 207, 1) 35%,
    rgba(153, 153, 153, 1) 100%
  );
  @apply fixed top-0 h-1;
  z-index: 1000;
  animation: progressBarAnimation 2s linear infinite;
}
@keyframes progressBarAnimation {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>

// THIS LINE REROUTES ALL THE ASYNC ASSETS TO OUR TEMPLATE URLS IN DIST
if (window.ykasettings && window.ykasettings.js_url) {
  __webpack_public_path__ = window.ykasettings.js_url; // eslint-disable-line no-undef

  //console.log( __webpack_public_path__ ); // eslint-disable-line no-undef
}

import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import "./assets/global.css";
import router from "./router";
import store from "./store";

//import axios from "axios";
//import VueAxios from "vue-axios";

//import timeago from 'vue-timeago3';

import { VueQueryPlugin } from "vue-query";

const VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

//console.log( VueQueryPluginOptions );

import OrbitComments from "@/lib/OrbitComments.vue";
import OrbitPosts from "@/lib/OrbitPosts.vue";
//import SocialShareQuery from "@/lib/SocialShareQuery.vue";
import Poll from "@/lib/Poll.vue";
import LoginRedirectButton from "@/lib/LoginRedirectButton.vue";
//import ThreeTabs from "@/lib/ThreeTabs.vue";
//import TrendingAuthors from "@/lib/TrendingAuthors.vue";

const app = createApp(App);

app.component("OrbitPosts", OrbitPosts);
app.component("OrbitComments", OrbitComments);
app.component("Poll", Poll);
app.component("LoginRedirectButton", LoginRedirectButton);
//app.component("ThreeTabs", ThreeTabs);
//app.component("TrendingAuthors", TrendingAuthors);

app
  .use(VueQueryPlugin, VueQueryPluginOptions)
  //.use(VueAxios, axios)
  .use(store)
  .use(router)
  //.use(timeago)
  .mount("#app");

import store from "@/store";

export default {
  debounce: null,

  /*
   * THIS FUNCTION IS NO LONGER BEING USED
   *
  getJsonContent: function (html) {
    var parser = new DOMParser();
    var parsedHtml = parser.parseFromString(html, "text/html");
    var nodes = parsedHtml.childNodes[0].childNodes[1].childNodes;
    return nodes;
  },
  */

  appendScripts: (htmlString) => {
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(htmlString, "text/html");
    var scripts = htmlDoc.getElementsByTagName("script");

    for (var index in scripts) {
      if (scripts[index].src) {
        /* REMOVE ALL THE SCRIPTS WITH THE SAME SOURCE
        var oldscript = document.querySelector('script[src="' + scripts[index].src + '"]');
        if( oldscript ){
          oldscript.parentNode.removeChild( oldscript )
          console.log( 'old script has been removed' )
        }
        */

        var $script = document.createElement("script");
        $script.src = scripts[index].src; // URL for the third-party library being loaded.
        document.body.appendChild($script);
        $script.onload = () => {
          console.log("Third Party Scripts have been loaded");
        };
      }
    }
  },

  // REMOVE EMPTY PARAMS THAT COME FROM DEFAULT ATTRIBUTES
  removeEmptyParams: (params) => {
    for (var key in params) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return params;
  },
  setDocumentTitle: (title) => {
    var newTitle = title;
    var decodeHTML = function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    };

    document.title = decodeHTML(newTitle);
  },

  getEditPostLink: function (post) {
    var route = {
      query: { post_id: post.id },
      // name: "FEP",
    };

    if (post.type === "post") {
      route["name"] = "FEP";
    }

    if (post.type === "note") {
      route["name"] = "CreateNote";
    }

    return route;
  },

  getPostLink: function (post) {
    var route = {
      name: "",
      params: { id: post.id, post: JSON.stringify(post) },
    };

    if (post.type != undefined && post.type == "post") {
      const month = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var postdate = new Date(post.date.replace(" ", "T"));
      //console.log( post.date );
      route.params.year = postdate.getFullYear();
      route.params.month = month[postdate.getMonth()];
      route.params.slug = post.slug ? decodeURIComponent(post.slug) : "";
      route.name = "SinglePost";
    } else if (post.type != undefined && post.type == "video") {
      route.params.slug = post.slug;
      route.name = "SingleVideo";
    } else if (post.type != undefined && post.type == "note") {
      route.params.slug = post.slug;
      route.name = "SingleNote";
    } else if (post.type != undefined && post.type == "poll") {
      route.params.slug = post.slug;
      route.name = "SinglePoll";
    }
    return route;
  },

  getAuthorLink: function (author) {
    //console.log( author );
    var route = {
      name: "Author",
      params: { slug: author.slug, author: JSON.stringify(author) },
    };
    return route;
  },

  getProfileLink: function () {
    var route = {
      name: "Author",
      params: { slug: store.state.localSettings.slug },
    };
    return route;
  },

  getTermLink: function (term) {
    var route = {
      name: "Archive",
      params: {
        id: term.id,
        slug: term.slug,
        taxonomy: term.taxonomy,
        term: JSON.stringify(term),
      },
    };
    return route;
  },
  decodeHTML: function (html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  },
  truncate: function (text, textLength = 80, suffix = "...") {
    var decodedText = this.decodeHTML(text);
    if (decodedText.length > textLength) {
      return decodedText.substring(0, textLength) + suffix;
    } else {
      return decodedText;
    }
  },
  formatDate: function (dateString) {
    if (dateString) {
      //console.log( dateString );

      var d = new Date(dateString.replace(" ", "T")),
        m_index = d.getMonth(),
        day = String(d.getDate()).padStart(2, "0"),
        year = d.getFullYear();

      //console.log( d + day );

      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[m_index] + " " + day + ", " + year;
    }
  },

  debounceEvent: function (callback, timeout = 600) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      callback();
    }, timeout);
  },

  lazyLoadImages: function () {
    const images = [...document.querySelectorAll("img.lazy:not(.lazy-done)")];

    const setImage = (image) => {
      if (!image.classList.contains("lazy-done")) {
        if (image.dataset.srcset) {
          image.srcset = image.dataset.srcset;
          image.sizes = image.dataset.sizes;
        }
        image.classList.add("lazy-done");
      }
    };

    images.forEach((image) => {
      if ("IntersectionObserver" in window) {
        const observer = new IntersectionObserver((changes) => {
          changes.forEach((change) => {
            if (change.isIntersecting) {
              setImage(image);
              observer.unobserve(image);
            }
          });
        });

        observer.observe(image);
      } else {
        setImage(image);
      }
    });
  },
  convertToObject(vueModel) {
    return JSON.parse(JSON.stringify(vueModel));
  },
  getCookie: function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  setCookie: function (cname, cvalue, exhours) {
    var d = new Date();
    d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Strict";
  },
  abbreviateNumber: function (value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  },

  closeAllModals: function(){
    store.commit( "comments/showModal", false );
    store.commit( "userFollow/showFollowModal", false );
  }

};
